<template>
  <div>
    <!--    <div-->
    <!--        v-if="productTypeId === '3' && client.id === 8 && current"-->
    <!--        class="client flex-center flex-column"-->
    <!--    >-->
    <!--      <v-container fluid class="fancy-cover py-8 px-0"> </v-container>-->
    <!--      <div class="header w-100 flex-center py-8">-->
    <!--        <v-container class="d-flex justify-space-between align-items-center">-->
    <!--          <div>-->
    <!--            <v-btn-->
    <!--                color="#272c33"-->
    <!--                depressed-->
    <!--                height="56"-->
    <!--                dark-->
    <!--                class="main-btn pl-2 mr-4 mb-4"-->
    <!--                @click="productsBack"-->
    <!--            >-->
    <!--              <v-icon>mdi-chevron-left</v-icon>-->
    <!--              {{ $t("back") }}-->
    <!--            </v-btn>-->
    <!--          </div>-->
    <!--        </v-container>-->
    <!--      </div>-->
    <!--      <v-container class="w-100 home-2 flex-center flex-column">-->
    <!--        <h2 class="w-100 text-center gold-text font-weight-light mb-7">Odaberite vrstu hrane</h2>-->
    <!--        <template v-for="(item, i) in filterCategories" >-->
    <!--        <v-row class="w-100 mx-0 flex-center" v-if="item.visible === 1 && item.product_type_id === 3 && item.id === 29" :key="i">-->
    <!--          <template v-for="(cat, index) in item.id">-->
    <!--          <v-col-->
    <!--                 :key="index"-->
    <!--                 cols="4" class="flex-center px-5">-->
    <!--            <div class="menu-item1" @click="addFilters(cat.id, item.id, $event)">-->
    <!--&lt;!&ndash;              <v-icon size="40" color="rgba(255, 255, 255, .4)">mdi-food</v-icon>&ndash;&gt;-->
    <!--              <p class="mt-4 ml-4">{{ cat.title }}</p>-->
    <!--            </div>-->
    <!--          </v-col>-->
    <!--          </template>-->
    <!--        </v-row>-->
    <!--        </template>-->
    <!--      </v-container>-->
    <!--      <v-container-->
    <!--          ref="myCarousel"-->
    <!--          fluid-->
    <!--          class="px-0 wines-3 flex-center flex-column"-->
    <!--      >-->
    <!--        <div class="w-100 d-flex flex-center flex-column">-->
    <!--          <h2-->
    <!--              class="w-100 text-center gold-text font-weight-bold mb-7"-->
    <!--              style="z-index: 999 !important"-->
    <!--          >-->
    <!--            {{ $t("food_class") }}-->
    <!--          </h2>-->

    <!--          <template v-for="(item, i) in filterCategories">-->
    <!--            <carousel-3d-->
    <!--                v-if="-->
    <!--                item.visible === 1 &&-->
    <!--                item.product_type_id === 3 &&-->
    <!--                item.id === 29-->
    <!--              "-->
    <!--                :key="i"-->
    <!--                class="carousel1"-->
    <!--                :controls-visible="true"-->
    <!--                :clickable="true"-->
    <!--                :autoplay="false"-->
    <!--                :perspective="0"-->
    <!--                :display="5"-->
    <!--                :scale="3"-->
    <!--                :space="450"-->
    <!--                border="0"-->
    <!--            >-->
    <!--              <slide-->
    <!--                  v-for="(cat, index) in item.id"-->
    <!--                  :key="index"-->
    <!--                  :index="index"-->
    <!--                  class="slide-cell"-->
    <!--              >-->
    <!--                <transition fade>-->
    <!--                  <div-->
    <!--                      class="carousel-wrap2"-->
    <!--                      style="bottom: 500px !important"-->
    <!--                      @click="addFilters(cat.id, item.id, $event)"-->
    <!--                  >-->
    <!--                    <div class="image-wrap1 position-relative">-->
    <!--                      <v-img-->
    <!--                          :src="require('@/assets/img/Hrana.png')"-->
    <!--                          alt="Hrana"-->
    <!--                          style="max-height: 300px"-->
    <!--                          class="cell-bottle"-->
    <!--                          contain-->
    <!--                      ></v-img>-->
    <!--                    </div>-->
    <!--                    <div class="carousel-text1 mt-7 px-2">-->
    <!--                      <h2 class="text-carousel w-100 text-center">-->
    <!--                        {{ cat.title }}-->
    <!--                      </h2>-->
    <!--                    </div>-->
    <!--                  </div>-->
    <!--                </transition>-->
    <!--              </slide>-->
    <!--            </carousel-3d>-->
    <!--          </template>-->
    <!--        </div>-->
    <!--      </v-container>-->
    <!--    </div>-->

    <div
      v-if="current_ads && showAds"
      class="home-overlay flex-center"
      @click="closeAd()"
      ref="overlay"
    >
      <v-img
        v-if="!current_ads.image_url == false"
        :src="current_ads.image_url"
        :alt="current_ads.title"
        class="big-ad-full"
        contain
      ></v-img>
      <div v-else style="height: auto; width: 100%">
        <v-img
          v-if="current_ads.video_url.substr(-4) === 'webp'"
          :src="current_ads.video_url"
        ></v-img>
        <div v-else>
          <video
            v-if="length_ads > 1"
            @ended="endVideo()"
            id="myVideo"
            autoplay
            height="100%"
            “
            :src="current_ads.video_url"
          ></video>
          <video
            v-else
            autoplay
            height="100%"
            width="100%"
            :src="current_ads.video_url"
            loop
          ></video>
        </div>
        <!--        <video v-else ref="myVideo" autoplay height="100%" width="100%" :src="current_ads.video_url" loop></video>-->
      </div>
    </div>
    <div v-else>
      <div
        v-if="productTypeId === '4' && current"
        class="client flex-center flex-column"
      >
        <v-container fluid class="fancy-cover py-8 px-0"></v-container>
        <div class="header w-100 flex-center py-8">
          <v-container class="d-flex justify-space-between align-items-center">
            <div style="z-index: 1000 !important">
              <v-btn
                color="#272c33"
                depressed
                height="56"
                dark
                class="main-btn pl-2 mr-4 mb-4"
                @click="productsBack"
              >
                <v-icon>mdi-chevron-left</v-icon>
                {{ $t("back") }}
              </v-btn>
            </div>
          </v-container>
        </div>
        <v-container
          ref="myCarousel"
          fluid
          class="px-0 wines-3 flex-center flex-column"
        >
          <div class="w-100 d-flex flex-center flex-column">
            <h2
              class="text-center gold-text font-weight-bold mb-7"
              style="z-index: 100 !important"
            >
              {{ $t("type_of_drinks") }}
            </h2>

            <template v-for="(item, i) in filterCategories">
              <carousel-3d
                v-if="
                  item.visible === 1 &&
                  item.product_type_id === 4 &&
                  item.id === 34
                "
                :key="i"
                class="carousel1"
                :controls-visible="true"
                :clickable="true"
                :autoplay="false"
                :perspective="0"
                :display="5"
                :scale="3"
                :space="450"
                border="0"
              >
                <slide
                  v-for="(cat, index) in getAvailableFilters(item.id)"
                  :key="index"
                  :index="parseInt(index)"
                  class="slide-cell"
                >
                  <transition fade>
                    <div
                      class="carousel-wrap2"
                      style="bottom: 500px !important"
                      @click="addFilters(cat.id, item.id, $event)"
                    >
                      <div class="image-wrap1 position-relative">
                        <v-img
                          :src="cat.image_url"
                          :lazy-src="cat.image_url"
                          alt="Vrste pića"
                          style="max-height: 300px"
                          class="cell-bottle"
                          contain
                        ></v-img>
                      </div>
                      <div class="carousel-text1 mt-7 px-2">
                        <h2 class="text-carousel w-100 text-center">
                          {{ cat.title }}
                        </h2>
                      </div>
                    </div>
                  </transition>
                </slide>
              </carousel-3d>
            </template>
          </div>
        </v-container>
      </div>
      <div v-else class="products">
        <v-container fluid class="fancy-cover py-8 px-0"></v-container>
        <div
          class="header w-100 flex-center py-8"
          style="z-index: 90 !important"
        >
          <v-container class="d-flex justify-space-between align-items-center">
            <!--          <div v-if="client.id === 8 && productTypeId === '3'">-->
            <!--            <v-btn-->
            <!--                v-if="!saved"-->
            <!--                color="#272c33"-->
            <!--                depressed-->
            <!--                height="56"-->
            <!--                dark-->
            <!--                class="main-btn pl-2 mr-4 mb-4"-->
            <!--                @click="productsBackToFilters"-->
            <!--            >-->
            <!--              <v-icon>mdi-chevron-left</v-icon>-->
            <!--              {{ $t("back") }}-->
            <!--            </v-btn>-->
            <!--            <v-btn-->
            <!--                color="#272c33"-->
            <!--                depressed-->
            <!--                height="50"-->
            <!--                dark-->
            <!--                class="main-btn pl-2 mr-4 mb-4"-->
            <!--                v-else-->
            <!--                @click="toggleCover"-->
            <!--            >-->
            <!--              <v-icon>mdi-chevron-left</v-icon>-->
            <!--              {{ $t("back") }}-->
            <!--            </v-btn>-->
            <!--          </div>-->
            <div v-if="productTypeId === '4'">
              <v-btn
                v-if="!saved"
                color="#272c33"
                depressed
                height="56"
                dark
                class="main-btn pl-2 mr-4 mb-4"
                @click="productsBackToFilters"
              >
                <v-icon>mdi-chevron-left</v-icon>
                {{ $t("back") }}
              </v-btn>
              <v-btn
                color="#272c33"
                depressed
                height="50"
                dark
                class="main-btn pl-2 mr-4 mb-4"
                v-else
                @click="toggleCover('ponovno postavi')"
              >
                <v-icon>mdi-chevron-left</v-icon>
                {{ $t("back") }}
              </v-btn>
            </div>
            <div v-else>
              <v-btn
                v-if="!saved"
                color="#272c33"
                depressed
                height="56"
                dark
                class="main-btn pl-2 mr-4 mb-4"
                @click="productsBack"
              >
                <v-icon>mdi-chevron-left</v-icon>
                {{ $t("back") }}
              </v-btn>
              <v-btn
                color="#272c33"
                depressed
                height="50"
                dark
                class="main-btn pl-2 mr-4 mb-4"
                v-else
                @click="toggleCover('ponovno postavi')"
              >
                <v-icon>mdi-chevron-left</v-icon>
                {{ $t("back") }}
              </v-btn>
            </div>
            <div v-if="!saved" style="display: flex" class="mr-6">
              <v-text-field
                v-model="search"
                dark
                height="50"
                background-color="#272c33"
                hide-details
                outlined
                style="width: 220px"
                color="#d7c194"
                :label="$t('search')"
                class="custom-field"
              >
                <v-icon slot="append" color="#d7c194">mdi-magnify</v-icon>
              </v-text-field>
              <v-menu
                transition="slide-y-transition"
                v-model="menu1"
                bottom
                offset-y
                min-width="200"
                persistent
                :close-on-content-click="false"
              >
                <template v-slot:activator="{ on }">
                  <v-btn
                    class="main-btn ml-4 mb-4 text-none"
                    height="56"
                    depressed
                    color="#d7c194"
                    v-bind:class="{ buttonActive: saved }"
                    v-on="on"
                    @click="menu1 = true"
                  >
                    <v-icon left>mdi-menu</v-icon>
                    {{ $t("menu1") }}
                    <!--                Menu-->
                  </v-btn>
                </template>
                <v-list dark>
                  <v-list-item-group>
                    <v-list-item
                      v-for="type in $store.getters.getProductTypes"
                      v-if="grouped_products[type.id]"
                      :key="type.title"
                      @click="goTo('/products/' + type.id)"
                    >
                      <v-list-item-icon>
                        <v-icon v-text="type.icon"></v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>
                        <h3 class="font-weight-regular">
                          {{ type.title }}
                        </h3>
                      </v-list-item-title>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-menu>
            </div>
            <div class="mr-5">
              <template v-if="client.qr_orders_active">
                <v-badge
                  v-if="$store.getters.favs.length > 0"
                  avatar
                  class="black--text"
                  color="white"
                  overlap
                >
                  <template v-slot:badge>
                    <v-avatar>
                      <span style="color: black !important">{{
                        $store.getters.favs.length
                      }}</span>
                    </v-avatar>
                  </template>
                  <v-btn
                    class="main-btn mb-4"
                    height="56"
                    depressed
                    dark
                    color="#272c33"
                    v-bind:class="{ buttonActive: saved }"
                    to="/cart"
                  >
                    <v-icon dark color="white">mdi-checkbox-marked </v-icon>
                  </v-btn>
                </v-badge>
                <v-btn
                  v-else
                  class="main-btn mb-4"
                  height="56"
                  depressed
                  dark
                  color="#272c33"
                  v-bind:class="{ buttonActive: saved }"
                  to="/cart"
                >
                  <v-icon dark large color="white">mdi-check </v-icon>
                </v-btn>
              </template>

              <v-btn
                v-if="saved"
                color="#d7c194"
                depressed
                class="main-btn ml-4 mr-5 mb-4"
                height="56"
                @click.stop="openOrderDialog"
              >
                <!--                <v-icon class="mr-2">mdi-filter-variant</v-icon>-->
                {{ $t("order") }}
              </v-btn>
              <v-menu
                transition="slide-y-transition"
                v-model="menu"
                bottom
                offset-y
                min-width="300"
                persistent
                :close-on-content-click="false"
                ref="filterMenu"
              >
                <template v-slot:activator="{ on }">
                  <v-btn
                    color="#d7c194"
                    depressed
                    class="main-btn ml-4 mr-5 mb-4"
                    height="56"
                    v-on="on"
                    @click="menu = true"
                  >
                    <v-icon class="mr-2">mdi-filter-variant</v-icon>
                    {{ $t("filters") }}
                  </v-btn>
                </template>
                <v-list dark class="filter-menu">
                  <v-list-item>
                    <v-list-item-title>
                      <h2 class="font-weight-regular">
                        {{ $t("filters") }}
                      </h2>
                    </v-list-item-title>
                  </v-list-item>
                  <v-divider></v-divider>
                  <div v-if="client.id === 8 && productTypeId === '3'">
                    <template v-for="(cat, index) in filterCategories">
                      <div
                        :key="index"
                        v-if="
                          cat.visible === 1 &&
                          cat.product_type_id === 3 &&
                          cat.id !== 29
                        "
                      >
                        <v-list-item>
                          <div class="flex-center flex-column w-100">
                            <v-list-item-title class="w-100 py-5">
                              <h3 class="white--secondary font-weight-regular">
                                {{ cat.title }}
                              </h3>
                            </v-list-item-title>
                            <v-list-item-title
                              v-for="(item, index) in getAvailableFilters(
                                cat.id
                              )"
                              :key="index"
                              class="w-100"
                            >
                              <v-checkbox
                                :label="item.title"
                                v-model="newFilters[item.id]"
                                @change="newFilter(item.id, cat.id, $event)"
                                color="#d7c194"
                                class="ma-0 filter-check w-100"
                              ></v-checkbox>
                            </v-list-item-title>
                          </div>
                        </v-list-item>
                        <v-divider></v-divider>
                      </div>
                    </template>
                  </div>
                  <div v-else>
                    <div
                      v-for="(cat, index) in filterCategories"
                      :key="index"
                      v-if="
                        cat.visible == 1 &&
                        cat.product_type_id == productTypeId &&
                        cat.id != 34
                      "
                    >
                      <v-list-item>
                        <div class="flex-center flex-column w-100">
                          <v-list-item-title class="w-100 py-5">
                            <h3 class="white--secondary font-weight-regular">
                              {{ cat.title }}
                            </h3>
                          </v-list-item-title>
                          <v-list-item-title
                            v-for="(item, index) in getAvailableFilters(cat.id)"
                            :key="index"
                            class="w-100"
                          >
                            <v-checkbox
                              :label="item.title"
                              v-model="newFilters[item.id]"
                              @change="newFilter(item.id, cat.id, $event)"
                              color="#d7c194"
                              class="ma-0 filter-check w-100"
                            ></v-checkbox>
                          </v-list-item-title>
                        </div>
                      </v-list-item>
                      <v-divider></v-divider>
                    </div>
                  </div>
                </v-list>
                <v-btn
                  class="w-100 main-btn text--primary filterBtn"
                  depressed
                  tile
                  color="#d7c194"
                  @click="
                    filterProducts();
                    menu = false;
                  "
                  >{{ $t("confirm") }}
                </v-btn>
                <v-btn
                  class="w-100 main-btn text--primary filterBtn"
                  depressed
                  tile
                  color="#212121"
                  @click="
                    clearFilters();
                    menu = false;
                  "
                >
                  <span class="white--text">{{ $t("clear") }}</span>
                </v-btn>
              </v-menu>
              <v-btn
                color="#272c33"
                depressed
                v-bind:class="{ buttonActive: cover }"
                dark
                class="main-btn mr-2 mb-4"
                height="56"
                @click="toggleCover()"
              >
                <ui-cover v-if="cover"></ui-cover>
                <gen-list v-else></gen-list>
              </v-btn>
              <!--            <v-btn-->
              <!--                color="#272c33"-->
              <!--                depressed-->
              <!--                v-bind:class="{ buttonActive: list }"-->
              <!--                dark-->
              <!--                class="main-btn mr-2 mb-4"-->
              <!--                height="56"-->
              <!--                @click="toggleList()"-->
              <!--            >-->
              <!--              <gen-list></gen-list>-->
              <!--            </v-btn>-->
              <!--            <v-btn-->
              <!--                color="#272c33"-->
              <!--                depressed-->
              <!--                v-bind:class="{ buttonActive: grid }"-->
              <!--                dark-->
              <!--                class="main-btn mr-2 mb-4"-->
              <!--                height="56"-->
              <!--                @click="toggleGrid()"-->
              <!--            >-->
              <!--              <ui-tiles></ui-tiles>-->
              <!--            </v-btn>-->
            </div>
          </v-container>
        </div>
        <v-container
          class="wines-2 products-2"
          ref="productDisplay"
          v-if="products.length > 0 && !cover"
        >
          <div v-if="list" class="mt-7">
            <v-row align="start" justify="start">
              <v-col :cols="['3', '2'].includes(productTypeId) ? '8' : '12'">
                <div
                  :class="['3', '2'].includes(productTypeId) ? 'videoteka' : ''"
                  id="containerDiv"
                >
                  <div
                    v-for="(item, index) in products"
                    :key="index"
                    @click="openProduct(item)"
                  >
                    <list-item
                      :data="item"
                      :class="
                        index === 0 && productTypeId === '3' && list
                          ? 'mt-8'
                          : ''
                      "
                    ></list-item>
                  </div>
                </div>
                <v-row align="center" justify="center">
                  <v-col cols="12" class="text-center">
                    <div style="margin-top: -15px">
                      <v-icon color="white" v-if="products.length > 2"
                        >mdi-chevron-double-down</v-icon
                      >
                    </div>
                  </v-col>
                </v-row>
              </v-col>
              <v-col v-if="productTypeId === '3'" cols="4">
                <template v-for="(item, i) in filterCategories">
                  <div
                    :key="i"
                    style="height: 100%; overflow: hidden !important"
                  >
                    <div
                      v-if="
                        item.product_type_id === 3 && item.id === 29 && !saved
                      "
                      class="videoteka"
                    >
                      <template
                        v-for="(cat, index) in getAvailableFilters(item.id)"
                      >
                        <v-card
                          :key="index"
                          flat
                          outlined
                          style="background-color: transparent"
                          :class="index !== 0 ? 'mt-4' : ''"
                          @click="
                            addFiltersFoodCategoryList(
                              cat,
                              item.id,
                              $event + 1
                            );
                            active_index = index;
                          "
                        >
                          <v-row align="center" justify="center">
                            <v-col cols="12">
                              <div v-if="index === active_index">
                                <h4
                                  class="text-carousel-2 active-text w-100 text-center"
                                >
                                  {{ cat.title }}
                                </h4>
                                <!--                                <h5 v-else class="text-carousel-3 w-100 text-center">-->
                                <!--                                  {{ cat.title }}-->
                                <!--                                </h5>-->
                              </div>
                              <div v-else>
                                <h4 class="text-carousel-2 w-100 text-center">
                                  {{ cat.title }}
                                </h4>
                                <!--                                <h5 v-else class="text-carousel-3 w-100 text-center">-->
                                <!--                                  {{ cat.title }}-->
                                <!--                                </h5>-->
                              </div>
                            </v-col>
                          </v-row>
                        </v-card>
                      </template>
                    </div>
                  </div>
                </template>
              </v-col>
              <v-col v-if="productTypeId === '2'" cols="4">
                <template v-for="(item, i) in filterCategories">
                  <div
                    :key="i"
                    style="height: 100%; overflow: hidden !important"
                  >
                    <div
                      v-if="
                        item.product_type_id === 2 && item.id === 17 && !saved
                      "
                      class="videoteka"
                    >
                      <template
                        v-for="(cat, index) in getAvailableFilters(item.id)"
                      >
                        <v-card
                          :key="index"
                          flat
                          outlined
                          style="background-color: transparent"
                          :class="index !== 0 ? 'mt-4' : ''"
                          @click="
                            addFiltersFoodCategoryList(
                              cat,
                              item.id,
                              $event + 1
                            );
                            active_index = index;
                          "
                        >
                          <v-row align="center" justify="center">
                            <v-col cols="12">
                              <div v-if="index === active_index">
                                <h4
                                  class="text-carousel-2 active-text w-100 text-center"
                                >
                                  {{ cat.title }}
                                </h4>
                                <!--                                <h5 v-else class="text-carousel-3 w-100 text-center">-->
                                <!--                                  {{ cat.title }}-->
                                <!--                                </h5>-->
                              </div>
                              <div v-else>
                                <h4 class="text-carousel-2 w-100 text-center">
                                  {{ cat.title }}
                                </h4>
                                <!--                                <h5 v-else class="text-carousel-3 w-100 text-center">-->
                                <!--                                  {{ cat.title }}-->
                                <!--                                </h5>-->
                              </div>
                            </v-col>
                          </v-row>
                        </v-card>
                      </template>
                    </div>
                  </div>
                </template>
                <!--                <template v-for="(item, i) in filterCategories">-->
                <!--                  <swiper v-if="item.product_type_id === 3 && item.id === 29 && !saved" :key="i" ref="mySwiper"-->
                <!--                          style="max-height: 400px !important" class="swiper" :options="swiperOption">-->
                <!--                    <swiper-slide v-for="(cat, index) in getAvailableFilters(item.id)"-->
                <!--                                  :key="index"-->
                <!--                                  :index="index" class="white&#45;&#45;text"-->
                <!--                                  style="max-height: 50px !important; height: 400px !important"-->
                <!--                                  @click="addFiltersFoodCategory(getAvailableFilters(item.id), item.id, $event+1)">-->
                <!--                      <transition fade>-->
                <!--                        <div class="">-->
                <!--                          <div class="">-->
                <!--                            <h4 v-if="cat.title.length <20" class="text-carousel-2 w-100 text-center">-->
                <!--                              {{ cat.title }}-->
                <!--                            </h4>-->
                <!--                            <h5 v-else class="text-carousel-3 w-100 text-center">-->
                <!--                              {{ cat.title }}-->
                <!--                            </h5>-->
                <!--                          </div>-->
                <!--                        </div>-->
                <!--                      </transition>-->
                <!--                    </swiper-slide>-->
                <!--                  </swiper>-->
                <!--                </template>-->
              </v-col>
            </v-row>
          </div>
          <div v-if="grid">
            <v-row class="w-100 d-flex justify-center mx-0">
              <v-col
                md="4"
                cols="12"
                class="d-flex justify-center align-center w-100 grid-col products-col"
                v-for="(item, index) in products"
                :key="index"
                @click="openProduct(item)"
              >
                <grid-item :data="item" class="w-100"></grid-item>
              </v-col>
            </v-row>
          </div>
          <div v-if="saved">
            <v-row
              class="w-100 d-flex justify-center mx-0"
              style="margin-top: 50px"
            >
              <v-col
                md="4"
                cols="12"
                class="d-flex justify-center align-center w-100 grid-col"
                v-for="(fav, index) in $store.getters.favs"
                :key="index"
              >
                <grid-item :data="fav" class="w-100"></grid-item>
              </v-col>
            </v-row>
          </div>
          <v-container
            class="d-flex justify-center align-center products-4"
            v-if="
              products.length == 0 ||
              (saved && this.$store.getters.favs.length == 0)
            "
          >
            <h2 class="mb-0 w-100 text-center white--text">
              {{ $t("products") }}
            </h2>
          </v-container>
        </v-container>
        <v-container
          v-if="products.length > 0 && cover"
          fluid
          class="px-0 wines-3"
        >
          <div
            class="w-100 d-flex"
            v-if="productTypeId !== '3' && productTypeId !== '4'"
          >
            <carousel-3d
              class="w-100 carousel"
              :controls-visible="true"
              :clickable="true"
              :autoplay="false"
              :perspective="0"
              :display="5"
              :space="450"
              :autoplayHoverPause="true"
              border="0"
            >
              <slide
                v-for="(item, index) in products"
                :key="index"
                :index="parseInt(index)"
                class="slide-cell"
              >
                <div class="carousel-wrap" @click="openProduct(item)">
                  <div
                    class="carousel-div"
                    v-bind:class="{ highlighted: item.promotion == 1 }"
                  >
                    <v-btn
                      class="ma-2 list-button-2"
                      tile
                      depressed
                      fab
                      dark
                      small
                      color="black"
                      @click.stop="addToFav(item)"
                      v-if="client.qr_orders_active"
                    >
                      <v-icon dark color="white" v-if="isFav(item.id)">
                        mdi-check
                      </v-icon>
                      <v-icon dark color="white" v-else>
                        mdi-checkbox-marked
                      </v-icon>
                    </v-btn>
                  </div>
                  <div class="image-wrap">
                    <img
                      :src="item.image_url"
                      :lazy-src="item.image_url"
                      alt="Bottle"
                      class="cell-bottle"
                    />
                  </div>
                  <div class="carousel-text mt-2 px-2">
                    <h2 class="white--text w-100 text-center">
                      {{ item.title }}
                    </h2>
                    <h3
                      v-if="productTypeId !== '2'"
                      class="white--text w-100 text-center my-2"
                      style="opacity: 60% !important"
                    >
                      <div
                        v-for="(item, key, index) in item.grouped_filters"
                        v-if="key == productType.description_filter_id"
                        :key="index"
                      >
                        <span
                          v-for="value in item"
                          :key="'ing' + value"
                          class="ingredient"
                        >
                          {{ $store.getters.getFilterById(key, value).title }}
                        </span>
                      </div>
                    </h3>
                    <div
                      class="prices d-flex mb-3"
                      v-if="productTypeId !== '4'"
                    >
                      <div class="d-flex justify-center align-center">
                        <span class="gold-text">
                          {{ item.price_bottle }}{{ client.currency }}
                          <!--                        <template v-if="item.amount"-->
                          <!--                      >/{{ item.amount }}</template-->
                          <!--                      >-->
                          <!--                        <template v-if="item.amount || item.pivot_amount">/{{-->
                          <!--                            item.pivot_amount ? item.pivot_amount : item.amount-->
                          <!--                          }}</template>-->
                          <template v-if="conversionRate">
                            <br />
                            {{ calculatePrice(item.price_bottle) }}{{ "kn" }}
                            <!--                                                      <template v-if="item.amount || item.pivot_amount">/{{-->
                            <!--                                                      item.pivot_amount ? item.pivot_amount : item.amount-->
                            <!--                                                    }}</template>-->
                          </template>
                        </span>
                      </div>
                    </div>
                    <div class="prices d-flex mb-5 align-center" v-else>
                      <div
                        class="d-flex justify-center align-center mr-3"
                        v-if="item.price_magnum"
                      >
                        <!--                    <v-icon color="#d7c194" large>mdi-bottle-wine</v-icon>-->
                        <img
                          :src="require('@/assets/img/whiskey.svg')"
                          style="width: 36px !important"
                          height="36px"
                        />
                        <span class="gold-text">
                          {{ item.price_magnum }}{{ client.currency }}
                          <template v-if="conversionRate">
                            <br />
                            {{ calculatePrice(item.price_magnum) }}{{ "kn" }}
                          </template>
                          <!--                          <template v-if="item.amount"-->
                          <!--                        >/{{ item.amount }}</template-->
                          <!--                        >-->
                        </span>
                      </div>
                      <div
                        class="d-flex justify-center align-center mr-3"
                        v-if="item.price_bottle"
                      >
                        <!--                    <v-icon color="#d7c194" large>mdi-bottle-wine</v-icon>-->
                        <img
                          :src="require('@/assets/img/whiskey.svg')"
                          style="width: 25px !important"
                          height="25px"
                        />
                        <span class="gold-text">
                          {{ item.price_bottle }}{{ client.currency }}
                          <!--                          <template v-if="item.amount"-->
                          <!--                        >/{{ item.amount }}</template-->
                          <!--                        >-->
                          <!--                          <template v-if="(item.amount || item.pivot_amount) && !item.price_glass">/{{-->
                          <!--                              item.pivot_amount ? item.pivot_amount : item.amount-->
                          <!--                            }}</template>-->
                          <template v-if="conversionRate">
                            <br />
                            {{ calculatePrice(item.price_bottle) }}{{ "kn" }}
                            <!--                                                        <template v-if="(item.amount || item.pivot_amount) && !item.price_glass">/{{-->
                            <!--                                                        item.pivot_amount ? item.pivot_amount : item.amount-->
                            <!--                                                      }}</template>-->
                          </template>
                        </span>
                      </div>
                      <template v-if="item.price_glass">
                        <span class="gold-text" v-if="item.price_bottle"
                          >-</span
                        >
                        <div class="d-flex justify-center align-center ml-3">
                          <v-icon color="#d7c194" size="20" left>
                            fas fa-glass-whiskey
                          </v-icon>
                          <span class="gold-text"
                            >{{ item.price_glass }}{{ client.currency }}
                            <!--                                                      <template v-if="item.amount || item.pivot_amount">/{{-->
                            <!--                                                          item.pivot_amount ? item.pivot_amount : item.amount-->
                            <!--                                                        }}</template>-->
                            <template v-if="conversionRate">
                              <br />
                              {{ calculatePrice(item.price_glass) }}{{ "kn" }}
                              <!--                                                          <template v-if="item.amount || item.pivot_amount">/{{-->
                              <!--                                                          item.pivot_amount ? item.pivot_amount : item.amount-->
                              <!--                                                        }}</template>-->
                            </template>
                          </span>
                        </div>
                      </template>
                    </div>
                  </div>
                </div>
              </slide>
            </carousel-3d>
          </div>
          <div
            class="w-100 d-flex"
            v-else-if="productTypeId === '3' || productTypeId === '4'"
          >
            <carousel-3d
              class="w-100 carousel"
              :controls-visible="true"
              :clickable="true"
              :autoplay="false"
              :perspective="0"
              :display="5"
              :space="450"
              :autoplayHoverPause="true"
              border="0"
            >
              <slide
                v-for="(item, index) in products"
                :key="index"
                :index="parseInt(index)"
                :class="
                  productTypeId === '3' && client.id === 8
                    ? 'slide-cell-3'
                    : 'slide-cell'
                "
              >
                <div class="carousel-wrap" @click="openProduct(item)">
                  <div
                    class="carousel-div"
                    v-bind:class="{ highlighted: item.promotion == 1 }"
                  >
                    <v-btn
                      class="ma-2 list-button-2"
                      tile
                      depressed
                      fab
                      dark
                      small
                      color="black"
                      @click.stop="addToFav(item)"
                      v-if="client.qr_orders_active"
                    >
                      <v-icon dark color="white" v-if="isFav(item.id)">
                        mdi-check
                      </v-icon>
                      <v-icon dark color="white" v-else>
                        mdi-checkbox-marked
                      </v-icon>
                    </v-btn>
                  </div>
                  <div class="image-wrap mb-4">
                    <img
                      :src="item.image_url"
                      alt="Bottle"
                      class="cell-bottle"
                    />
                  </div>
                  <div class="carousel-text mt-2" style="width: 70%">
                    <h2
                      v-if="item.title.length < 30"
                      class="white--text w-100 text-center"
                    >
                      {{ item.title }}
                    </h2>
                    <h4 v-else class="white--text w-100 text-center">
                      {{ item.title }}
                    </h4>
                    <!--                  <h3-->
                    <!--                      class="white&#45;&#45;text w-100 text-center my-2"-->
                    <!--                      style="opacity: 60% !important"-->
                    <!--                  >-->
                    <!--                    <div-->
                    <!--                        v-for="(item, key, index) in item.grouped_filters"-->
                    <!--                        v-if="key == productType.description_filter_id"-->
                    <!--                        :key="index"-->
                    <!--                    >-->
                    <!--                      <div-->
                    <!--                          v-for="value in item"-->
                    <!--                          :key="'ing' + value"-->
                    <!--                          class="ingredient"-->

                    <!--                      >-->
                    <!--                        <span v-if="$store.getters.getFilterById(key, value).title.length < 30">{{$store.getters.getFilterById(key, value).title}}</span>-->
                    <!--                        <h5 style="font-size: 14px" class="mb-2" v-else>{{$store.getters.getFilterById(key, value).title}}</h5>-->
                    <!--                      </div>-->
                    <!--                    </div>-->
                    <!--                  </h3>-->
                    <div
                      class="prices d-flex mb-3 mt-2"
                      v-if="productTypeId !== '4'"
                    >
                      <div class="d-flex justify-center align-center">
                        <span class="gold-text">
                          {{ item.price_bottle }}{{ client.currency }}
                          <!--                                                <template v-if="item.amount"-->
                          <!--                                              >/{{ item.amount }}</template-->
                          <!--                                              >-->
                          <template v-if="item.amount || item.pivot_amount"
                            >/{{
                              item.pivot_amount
                                ? item.pivot_amount
                                : item.amount
                            }}</template
                          >
                          <template v-if="conversionRate">
                            <br />
                            {{ calculatePrice(item.price_bottle) }}{{ "kn" }}
                            <template v-if="item.amount || item.pivot_amount"
                              >/{{
                                item.pivot_amount
                                  ? item.pivot_amount
                                  : item.amount
                              }}</template
                            >
                          </template>
                        </span>
                      </div>
                    </div>
                    <div class="prices d-flex mb-5 align-center" v-else>
                      <div
                        class="d-flex justify-center align-center mr-3"
                        v-if="item.price_magnum"
                      >
                        <!--                    <v-icon color="#d7c194" large>mdi-bottle-wine</v-icon>-->
                        <img
                          :src="require('@/assets/img/whiskey.svg')"
                          style="width: 36px"
                          height="36px"
                        />
                        <span class="gold-text" style="font-size: 16px">
                          {{ item.price_magnum }}{{ client.currency }}
                          <template v-if="item.amount"
                            >/{{ item.amount }}</template
                          >
                          <template v-if="item.amount || item.pivot_amount"
                            >/{{
                              item.pivot_amount
                                ? item.pivot_amount
                                : item.amount
                            }}</template
                          >
                          <template v-if="conversionRate">
                            <br />
                            {{ calculatePrice(item.price_magnum) }}{{ "kn" }}
                            <template v-if="item.amount || item.pivot_amount"
                              >/{{
                                item.pivot_amount
                                  ? item.pivot_amount
                                  : item.amount
                              }}</template
                            >
                          </template>
                        </span>
                      </div>
                      <span class="gold-text" v-if="item.price_magnum">-</span>
                      <div
                        class="d-flex justify-center align-center mr-3"
                        v-if="item.price_bottle"
                      >
                        <!--                    <v-icon color="#d7c194" large>mdi-bottle-wine</v-icon>-->
                        <img
                          :src="require('@/assets/img/whiskey.svg')"
                          style="width: 24px !important"
                          height="24px"
                        />
                        <span class="gold-text" style="font-size: 16px">
                          {{ item.price_bottle }}{{ client.currency }}
                          <template v-if="item.amount"
                            >/{{ item.amount }}</template
                          >
                          <template
                            v-if="
                              (item.amount || item.pivot_amount) &&
                              !item.price_glass
                            "
                            >/{{
                              item.pivot_amount
                                ? item.pivot_amount
                                : item.amount
                            }}</template
                          >
                          <template v-if="conversionRate">
                            <br />
                            {{ calculatePrice(item.price_bottle) }}{{ "kn" }}
                            <template
                              v-if="
                                (item.amount || item.pivot_amount) &&
                                !item.price_glass
                              "
                              >/{{
                                item.pivot_amount
                                  ? item.pivot_amount
                                  : item.amount
                              }}</template
                            >
                          </template>
                        </span>
                      </div>
                      <template v-if="item.price_glass">
                        <span class="gold-text" style="font-size: 16px">-</span>
                        <div class="d-flex justify-center align-center ml-3">
                          <v-icon color="#d7c194" size="18" left>
                            fas fa-glass-whiskey
                          </v-icon>
                          <span class="gold-text" style="font-size: 16px"
                            >{{ item.price_glass }}{{ client.currency }}
                            <template v-if="item.amount || item.pivot_amount"
                              >/{{
                                item.pivot_amount
                                  ? item.pivot_amount
                                  : item.amount
                              }}</template
                            >
                            <template v-if="conversionRate">
                              <br />
                              {{ calculatePrice(item.price_glass) }}{{ "kn" }}
                              <template v-if="item.amount || item.pivot_amount"
                                >/{{
                                  item.pivot_amount
                                    ? item.pivot_amount
                                    : item.amount
                                }}</template
                              >
                            </template>
                          </span>
                        </div>
                      </template>
                    </div>
                  </div>
                </div>
              </slide>
            </carousel-3d>
          </div>
        </v-container>
        <v-container
          fluid
          class="footer py-3"
          v-if="productTypeId === '3' && !list"
        >
          <template v-for="(item, i) in filterCategories">
            <carousel-3d
              v-if="item.product_type_id === 3 && item.id === 29 && !saved"
              :key="i"
              :controls-visible="false"
              :clickable="true"
              :autoplay="false"
              :perspective="0"
              :display="7"
              :scale="5"
              :space="300"
              border="0"
              height="60"
              ref="carousel4"
              @after-slide-change="
                addFiltersFoodCategory(
                  getAvailableFilters(item.id),
                  item.id,
                  $event + 1
                )
              "
              class="text-center"
            >
              <slide
                v-for="(cat, index) in getAvailableFilters(item.id)"
                :key="index"
                :index="parseInt(index)"
                class="slide-cell-2"
                style="height: 40px !important"
              >
                <transition fade>
                  <div class="">
                    <div class="">
                      <h3
                        v-if="cat.title.length < 20"
                        class="text-carousel-2 w-100 text-center"
                      >
                        {{ cat.title }}
                      </h3>
                      <h4 v-else class="text-carousel-3 w-100 text-center">
                        {{ cat.title }}
                      </h4>
                    </div>
                  </div>
                </transition>
              </slide>
            </carousel-3d>
          </template>
        </v-container>
        <v-container
          fluid
          class="footer py-3"
          v-if="productTypeId === '2' && !list"
        >
          <template v-for="(item, i) in filterCategories">
            <carousel-3d
              v-if="item.product_type_id === 2 && item.id === 17 && !saved"
              :key="i"
              :controls-visible="false"
              :clickable="true"
              :autoplay="false"
              :perspective="0"
              :display="7"
              :scale="5"
              :space="300"
              border="0"
              height="60"
              ref="carousel4"
              @after-slide-change="
                addFiltersFoodCategory(
                  getAvailableFilters(item.id),
                  item.id,
                  $event + 1
                )
              "
              class="text-center"
            >
              <slide
                v-for="(cat, index) in getAvailableFilters(item.id)"
                :key="index"
                :index="parseInt(index)"
                class="slide-cell-2"
                style="height: 40px !important"
              >
                <transition fade>
                  <div class="">
                    <div class="">
                      <h3
                        v-if="cat.title.length < 20"
                        class="text-carousel-2 w-100 text-center"
                      >
                        {{ cat.title }}
                      </h3>
                      <h4 v-else class="text-carousel-3 w-100 text-center">
                        {{ cat.title }}
                      </h4>
                    </div>
                  </div>
                </transition>
              </slide>
            </carousel-3d>
          </template>
        </v-container>

        <v-dialog
          v-model="productsDialog"
          v-if="selectedProduct"
          width="75%"
          heigth="100%"
          @click:outside="productsDialog = false"
          style="z-index: 999 !important"
        >
          <div
            class="details-view product-details position-relative pa-5 backGradient"
          >
            <div class="d-flex justify-space-between w-100 dialog-button">
              <v-btn
                class="mx-2"
                tile
                depressed
                fab
                large
                color="transparent"
                @click.stop="addToFav(selectedProduct)"
                v-if="client.qr_orders_active"
              >
                <v-icon
                  dark
                  color="rgba(0, 0, 0, .6)"
                  v-if="isFav(selectedProduct.id)"
                >
                  mdi-check
                </v-icon>
                <v-icon dark color="rgba(0, 0, 0, .6)" v-else>
                  mdi-checkbox-marked</v-icon
                >
              </v-btn>
              <h1 class="font-weight-bold text-center pt-2">
                {{ selectedProduct.title }}
              </h1>
              <v-btn
                class="ma-2"
                tile
                depressed
                large
                color="#d7c194"
                @click="closeProduct"
              >
                {{ $t("close") }}
                <v-icon dark color="#000" class="ml-3">mdi-close</v-icon>
              </v-btn>
            </div>

            <v-row class="w-100 d-flex">
              <v-col
                cols="12"
                :md="selectedProduct.video_url ? '5' : '6'"
                v-if="!selectedProduct.description"
                class="d-flex flex-column justify-content-center"
              >
                <h2
                  v-if="$route.params.productType !== '4'"
                  class="burgundy-text font-weight-bold align-self-start mb-6"
                >
                  {{ selectedProduct.price_bottle }}{{ client.currency }}
                  <!--                  <template v-if="selectedProduct.amount">/{{ selectedProduct.amount }}</template>-->
                  <template
                    v-if="
                      selectedProduct.amount || selectedProduct.pivot_amount
                    "
                  >
                    /{{
                      selectedProduct.pivot_amount
                        ? selectedProduct.pivot_amount
                        : selectedProduct.amount
                    }}
                  </template>
                  <template v-if="conversionRate">
                    <br />
                    {{ calculatePrice(selectedProduct.price_bottle) }}{{ "kn" }}
                    <template
                      v-if="
                        selectedProduct.amount || selectedProduct.pivot_amount
                      "
                    >
                      /{{
                        selectedProduct.pivot_amount
                          ? selectedProduct.pivot_amount
                          : selectedProduct.amount
                      }}
                    </template>
                  </template>
                </h2>
                <h3 class="w-100 font-weight-bold black--text pb-2">
                  {{ $t("description") }}
                </h3>
                <span class="text--secondary w-100 font-desc">
                  {{ selectedProduct.title }}
                </span>
                <div class="mt-3">
                  <!--                  <div v-for="(item, key, index) in selectedProduct.grouped_filters"-->
                  <!--                       v-if="key == productType.description_filter_id"-->
                  <!--                       :key="index">-->
                  <!--                    <h3 class="w-100 font-weight-bold black&#45;&#45;text pb-2">-->
                  <!--                      {{ filterCategories.find(x => x.id === parseInt(key)).title }}</h3>-->
                  <!--                    <span-->
                  <!--                        v-for="value in item"-->
                  <!--                        :key="'ing' + value"-->
                  <!--                        class="ingredient text&#45;&#45;secondary w-100 font-desc">-->
                  <!--                        {{ $store.getters.getFilterById(key, value).title }}-->
                  <!--                      </span>-->
                  <!--                  </div>-->

                  <v-row
                    v-for="(filter, k, j) in selectedProduct.grouped_filters"
                    :key="j"
                  >
                    <v-col
                      cols="12"
                      v-if="
                        k !== '24' &&
                        $store.getters.getFilterCatById(k).visible_details == 1
                      "
                      class="text-start"
                    >
                      <h3 class="text-left">
                        {{ $store.getters.getFilterCatById(k).title }}
                      </h3>
                      <template
                        v-for="(
                          item, key, i
                        ) in selectedProduct.grouped_filters"
                      >
                        <div :key="i" v-if="key === k" class="align-start">
                          <div
                            v-for="(value, index) in item"
                            :key="index"
                            class=""
                            style="align-items: start; float: left"
                            :style="index !== item.length ? 'float: left' : ''"
                          >
                            <img
                              v-if="
                                $store.getters.getFilterById(key, value)
                                  .image_url
                              "
                              height="40"
                              :src="
                                $store.getters.getFilterById(key, value)
                                  .image_url
                              "
                            />
                            <!--                              <br>-->
                            <p
                              v-else
                              class="text--secondary w-100 font-desc"
                              style="height: 100%; display: inline"
                            >
                              {{ $store.getters.getFilterById(key, value).title
                              }}{{ index !== item.length - 1 ? ", " : "" }}
                            </p>
                          </div>
                        </div>
                      </template>
                    </v-col>
                  </v-row>

                  <div
                    v-if="
                      $route.params.productType === '4' &&
                      selectedProduct.alcohol_percent
                    "
                    class="mt-3"
                  >
                    <h3 class="w-100 font-weight-bold black--text pb-2">
                      {{ $t("alcohol") }}
                    </h3>
                    <span class="ingredient text--secondary w-100 font-desc">
                      {{ selectedProduct.alcohol_percent }}%
                    </span>
                  </div>
                </div>
              </v-col>
              <v-col
                cols="12"
                :md="selectedProduct.video_url ? '5' : '6'"
                v-if="selectedProduct.description"
                class="d-flex flex-column justify-content-center"
              >
                <h2
                  v-if="$route.params.productType !== '4'"
                  class="burgundy-text font-weight-bold align-self-start mb-6"
                >
                  {{ selectedProduct.price_bottle }}{{ client.currency }}
                  <!--                  <template v-if="selectedProduct.amount">/{{ selectedProduct.amount }}</template>-->
                  <template
                    v-if="
                      selectedProduct.amount || selectedProduct.pivot_amount
                    "
                  >
                    /{{
                      selectedProduct.pivot_amount
                        ? selectedProduct.pivot_amount
                        : selectedProduct.amount
                    }}
                  </template>
                  <template v-if="conversionRate">
                    <br />
                    {{ calculatePrice(selectedProduct.price_bottle) }}{{ "kn" }}
                    <template
                      v-if="
                        selectedProduct.amount || selectedProduct.pivot_amount
                      "
                    >
                      /{{
                        selectedProduct.pivot_amount
                          ? selectedProduct.pivot_amount
                          : selectedProduct.amount
                      }}
                    </template>
                  </template>
                </h2>
                <h3 class="w-100 font-weight-bold black--text pb-2">
                  {{ $t("description") }}
                </h3>
                <span class="text--secondary w-100 font-desc">
                  {{ selectedProduct.description }}
                </span>
                <div class="mt-3">
                  <!--                  <div v-for="(item, key, index) in selectedProduct.grouped_filters"-->
                  <!--                       v-if="key == productType.description_filter_id"-->
                  <!--                       :key="index">-->
                  <!--                    <h3 class="w-100 font-weight-bold black&#45;&#45;text pb-2">-->
                  <!--                      {{ filterCategories.find(x => x.id === parseInt(key)).title }}</h3>-->
                  <!--                    <span-->
                  <!--                        v-for="value in item"-->
                  <!--                        :key="'ing' + value"-->
                  <!--                        class="ingredient text&#45;&#45;secondary w-100 font-desc">-->
                  <!--                        {{ $store.getters.getFilterById(key, value).title }}-->
                  <!--                      </span>-->
                  <!--                  </div>-->

                  <v-row
                    v-for="(filter, k, j) in selectedProduct.grouped_filters"
                    :key="j"
                  >
                    <v-col
                      cols="12"
                      v-if="
                        k !== '24' &&
                        $store.getters.getFilterCatById(k).visible_details == 1
                      "
                      class="text-start"
                    >
                      <h3 class="text-left">
                        {{ $store.getters.getFilterCatById(k).title }}
                      </h3>
                      <template
                        v-for="(
                          item, key, i
                        ) in selectedProduct.grouped_filters"
                      >
                        <div :key="i" v-if="key === k" class="align-start">
                          <div
                            v-for="(value, index) in item"
                            :key="index"
                            class=""
                            style="align-items: start; float: left"
                            :style="index !== item.length ? 'float: left' : ''"
                          >
                            <img
                              v-if="
                                $store.getters.getFilterById(key, value)
                                  .image_url
                              "
                              height="40"
                              :src="
                                $store.getters.getFilterById(key, value)
                                  .image_url
                              "
                            />
                            <!--                              <br>-->
                            <p
                              v-else
                              class="text--secondary w-100 font-desc"
                              style="height: 100%; display: inline"
                            >
                              {{ $store.getters.getFilterById(key, value).title
                              }}{{ index !== item.length - 1 ? ", " : "" }}
                            </p>
                          </div>
                        </div>
                      </template>
                    </v-col>
                  </v-row>

                  <div
                    v-if="
                      $route.params.productType === '4' &&
                      selectedProduct.alcohol_percent
                    "
                    class="mt-3"
                  >
                    <h3 class="w-100 font-weight-bold black--text pb-2">
                      {{ $t("alcohol") }}
                    </h3>
                    <span class="ingredient text--secondary w-100 font-desc">
                      {{ selectedProduct.alcohol_percent }}%
                    </span>
                  </div>
                </div>
              </v-col>
              <v-col
                cols="12"
                :md="selectedProduct.video_url ? '7' : '6'"
                :class="{ 'col-md-12': false }"
                class="text-center px-0 py-6 flex-center"
              >
                <video
                  v-if="selectedProduct.video_url"
                  :src="selectedProduct.video_url"
                  autoplay
                  loop
                  class="productsVideo videoShadow"
                  :alt="selectedProduct.title"
                ></video>
                <img
                  v-else
                  :src="selectedProduct.image_url"
                  class="productsImage"
                  :alt="selectedProduct.title"
                />
              </v-col>
            </v-row>
            <v-row
              justify="center"
              align="center"
              v-if="$route.params.productType === '4'"
            >
              <v-col cols="12" class="py-0" fill-height>
                <div style="display: flex">
                  <v-img
                    v-if="selectedProduct.price_bottle"
                    :src="require('@/assets/img/whiskey_red.svg')"
                    style="width: 45px !important"
                    height="45px"
                    class="mt-3"
                  ></v-img>
                  <span
                    class="burgundy-text title mt-5"
                    v-if="selectedProduct.price_bottle"
                  >
                    {{ selectedProduct.price_bottle }}{{ client.currency }}
                    <template
                      v-if="
                        (selectedProduct.amount ||
                          selectedProduct.pivot_amount) &&
                        !selectedProduct.price_glass
                      "
                      >/{{
                        selectedProduct.pivot_amount
                          ? selectedProduct.pivot_amount
                          : selectedProduct.amount
                      }}</template
                    >
                    <template v-if="conversionRate">
                      <br />
                      {{ calculatePrice(selectedProduct.price_bottle)
                      }}{{ "kn" }}
                      <template
                        v-if="
                          (selectedProduct.amount ||
                            selectedProduct.pivot_amount) &&
                          !selectedProduct.price_glass
                        "
                        >/{{
                          selectedProduct.pivot_amount
                            ? selectedProduct.pivot_amount
                            : selectedProduct.amount
                        }}</template
                      >
                    </template>
                  </span>
                  <div v-if="selectedProduct.price_glass" style="display: flex">
                    <v-icon color="#800020" size="32" class="mt-3 ml-7" left>
                      fas fa-glass-whiskey
                    </v-icon>
                    <span class="burgundy-text title mt-5">
                      {{ selectedProduct.price_glass }}{{ client.currency }}
                      <template
                        v-if="
                          selectedProduct.amount || selectedProduct.pivot_amount
                        "
                        >/{{
                          selectedProduct.pivot_amount
                            ? selectedProduct.pivot_amount
                            : selectedProduct.amount
                        }}</template
                      >
                      <template v-if="conversionRate">
                        <br />
                        {{ calculatePrice(selectedProduct.price_glass)
                        }}{{ "kn" }}
                        <template
                          v-if="
                            selectedProduct.amount ||
                            selectedProduct.pivot_amount
                          "
                          >/{{
                            selectedProduct.pivot_amount
                              ? selectedProduct.pivot_amount
                              : selectedProduct.amount
                          }}</template
                        >
                      </template>
                    </span>
                  </div>
                </div>
              </v-col>
            </v-row>
            <!--            <v-row align="center" style="position:absolute; bottom: 20px; right: 20px"-->
            <!--                   v-if="$route.params.productType === '4' && selectedProduct.logo_text != null && selectedProduct.logo != null">-->
            <!--              <v-col cols="12" class="py-0 pr-0" fill-height>-->
            <!--                <div style="align-items: center; display: flex;">-->
            <!--                  <span>{{ selectedProduct.logo_text }}</span>-->
            <!--                  <img height="50px" :src="selectedProduct.logo"/>-->
            <!--                </div>-->
            <!--              </v-col>-->
            <!--            </v-row>-->
            <v-row
              align="center"
              style="position: absolute; bottom: 20px; right: 20px"
              v-if="
                (selectedProduct.logo_text ||
                  selectedProduct.logo ||
                  client.sponsor_text ||
                  client.sponsor_logo) &&
                productTypeId != 3
              "
            >
              <v-col cols="12" class="py-0 pr-0" fill-height>
                <div style="align-items: center; display: flex">
                  <span v-if="selectedProduct.logo_text" class="mr-4">{{
                    selectedProduct.logo_text
                  }}</span>
                  <span v-else-if="client.sponsor_text" class="mr-4">{{
                    client.sponsor_text
                  }}</span>
                  <img
                    v-if="selectedProduct.logo"
                    height="70px"
                    :src="selectedProduct.logo"
                  />
                  <img
                    v-else-if="client.sponsor_logo"
                    height="70px"
                    :src="client.sponsor_logo"
                  />
                </div>
              </v-col>
            </v-row>
            <v-row
              v-else-if="
                (selectedProduct.logo_text ||
                  selectedProduct.logo ||
                  client.sponsor_text ||
                  client.sponsor_logo) &&
                productTypeId == 3
              "
              align="center"
              style="position: absolute; bottom: 20px; left: 20px"
            >
              <v-col cols="12" class="py-0 pr-0" fill-height>
                <div style="align-items: center; display: flex">
                  <span v-if="selectedProduct.logo_text" class="mr-4">{{
                    selectedProduct.logo_text
                  }}</span>
                  <span v-else-if="client.sponsor_text" class="mr-4">{{
                    client.sponsor_text
                  }}</span>
                  <img
                    v-if="selectedProduct.logo"
                    height="40px"
                    :src="selectedProduct.logo"
                  />
                  <img
                    v-else-if="client.sponsor_logo"
                    height="40px"
                    :src="client.sponsor_logo"
                  />
                </div>
              </v-col>
            </v-row>
          </div>
        </v-dialog>
        <v-dialog v-model="orderDialog" class="order-dialog" max-width="500">
          <div class="pa-4">
            <v-form @submit.prevent="submitOrder" class="d-flex flex-column">
              <v-text-field
                v-model="tableNo"
                :label="$t('table_number')"
                color="black"
                required
              ></v-text-field>
              <v-textarea
                v-model="tableNo"
                :label="$t('note')"
                color="black"
              ></v-textarea>
              <v-btn
                color="#d7c194"
                depressed
                class="main-btn mb-1 align-self-end"
                width="100"
                height="56"
                type="submit"
              >
                {{ $t("order") }}
              </v-btn>
            </v-form>
          </div>
        </v-dialog>
      </div>
    </div>
  </div>
</template>
<script>
import ListItem from "../components/ListItem";
import GridItem from "../components/GridItem";
import UiTiles from "../assets/img/UiTiles";
import GenList from "../assets/img/GenList";
import UiCover from "../assets/img/UiCover";
import { directive, Swiper, SwiperSlide } from "vue-awesome-swiper";
import localforage from "localforage";

export default {
  name: "Products",
  components: {
    "list-item": ListItem,
    "grid-item": GridItem,
    "ui-tiles": UiTiles,
    "ui-cover": UiCover,
    "gen-list": GenList,
    Swiper,
    SwiperSlide,
  },
  directives: {
    swiper: directive,
  },
  computed: {
    client() {
      return this.$store.getters.getClient;
    },
    conversionRate() {
      return this.$store.getters.getClient.dual_price;
    },
    grouped_products() {
      return this.$store.getters.getGroupedProducts;
    },
    allProducts() {
      return this.$store.getters.getProductsById(this.productTypeId);
    },
    productType() {
      return this.$store.getters.getTypeById(this.productTypeId);
    },
    filterCategories() {
      return this.$store.getters.getFilters;
    },
    availableFilters() {
      let available = new Set();
      this.allProducts.forEach((item) => {
        item.filter_ids.forEach((id) => {
          available.add(id);
        });
      });
      return available;
    },
    ads() {
      return this.$store.getters.getAds;
    },
    showAds() {
      return this.$store.getters.showAds;
    },
    swiper() {
      return this.$refs.mySwiper.$swiper;
    },
  },
  data() {
    return {
      search: "",
      products: [],
      type_of_drinks: [],
      productTypeId: 2,
      current: true,
      list: false,
      grid: false,
      cover: true,
      menu: false,
      menu1: false,
      saved: false,
      filters: {},
      newFilters: {},
      oldCarouselIndex: 0,
      foodCategory: [],
      productsDialog: false,
      selectedProduct: null,
      time_spent: 0,
      interval: null,
      orderDialog: false,
      tableNo: "",
      active_index: 0,

      swiperOption: {
        slidesPerView: 3,
        direction: "vertical",
        spaceBetween: 30,
        loop: true,
        mousewheel: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },

        // direction: 'horizontal',
        // on: {
        //   resize: () => {
        //     this.$refs.mySwiper.$swiper.changeDirection(
        //         window.innerWidth <= 960
        //             ? 'vertical'
        //             : 'horizontal'
        //     )
        //   }
        // },
        // breakpoints: {
        //   1024: {
        //     slidesPerView: 4,
        //     spaceBetween: 10
        //   },
        //   768: {
        //     slidesPerView: 2,
        //     spaceBetween: 2
        //   },
        //   640: {
        //     slidesPerView: 1,
        //     spaceBetween: 2
        //   },
        //   320: {
        //     slidesPerView: 1,
        //     spaceBetween: 2
        //   }
        // }
      },

      //ads
      counter: 0,
      current_ads: null,
      setAdTimeout: null,
      length_ads: 0,
    };
  },
  methods: {
    calculatePrice(price) {
      return (price / this.conversionRate).toFixed(2).toString();
    },
    openProduct(product) {
      clearTimeout(this.menuTimeout);
      this.time_spent = 0;
      this.selectedProduct = product;
      this.productsDialog = true;
      this.increaseClicks(product);
      this.startTimer();
    },
    increaseClicks(product) {
      // dispatch action that updates clicks
      this.$store.dispatch("increaseClicks", {
        client_product_id: product.client_product_id,
      });
    },
    increaseTimeSpent(product, time_spent) {
      // dispatch action that updates clicks
      this.$store.dispatch("increaseTimeSpent", {
        client_product_id: product.client_product_id,
        time_spent: time_spent,
      });
    },
    startTimer() {
      this.interval = setInterval(this.incrementTime, 1000);
    },
    incrementTime() {
      if (this.interval != null) {
        this.time_spent = parseInt(this.time_spent) + 1;
      }
    },
    closeProduct() {
      clearInterval(this.interval);
      this.interval = null;
      this.increaseTimeSpent(this.selectedProduct, this.time_spent);
      this.time_spent = 0;
      this.productsDialog = false;
      this.selectedProduct = null;
    },
    newFilter(filterID, catID, e) {
      this.toggleFilter(filterID, catID, e);
      this.filterProducts();
    },
    toggleFilter(filterID, catID, e) {
      if (typeof this.filters[catID] == "undefined") {
        this.filters[catID] = [];
      }

      let catFilter = this.filters[catID];

      if (e) {
        catFilter.push(filterID);
      } else {
        catFilter.splice(catFilter.indexOf(filterID), 1);

        if (catFilter.length == 0) {
          delete this.filters[catID];
        }
      }
    },
    toggleCover(item) {
      if (item) {
        this.search = "";
        this.grid = false;
        this.list = false;
        this.cover = true;
        this.saved = false;
      } else {
        this.cover = !this.cover;
        if (this.cover === true) {
          this.search = "";
          this.grid = false;
          this.list = false;
          this.cover = true;
          this.saved = false;
          if (this.productTypeId === "2")
            localStorage.setItem("cocktail_grid", 0);
          else if (this.productTypeId === "3")
            localStorage.setItem("food_grid", 0);
          else if (this.productTypeId === "4")
            localStorage.setItem("drink_grid", 0);
        } else {
          this.grid = false;
          this.cover = false;
          this.list = true;
          this.saved = false;
          if (this.productTypeId === "2")
            localStorage.setItem("cocktail_grid", 1);
          else if (this.productTypeId === "3")
            localStorage.setItem("food_grid", 1);
          else if (this.productTypeId === "4")
            localStorage.setItem("drink_grid", 1);
          if (this.$refs.wineDisplay) {
            this.$refs.wineDisplay.scrollTo(0, 0);
          }
        }
      }
    },
    toggleList() {
      this.grid = false;
      this.cover = false;
      this.list = true;
      this.saved = false;
      if (this.$refs.productDisplay) {
        this.$refs.productDisplay.scrollTo(0, 0);
      }
    },
    toggleGrid() {
      this.cover = false;
      this.list = false;
      this.grid = true;
      this.saved = false;
      if (this.$refs.productDisplay) {
        this.$refs.productDisplay.scrollTo(0, 0);
      }
    },
    toggleSaved() {
      this.grid = false;
      this.cover = false;
      this.list = false;
      this.saved = true;
      if (this.$refs.wineDisplay) {
        this.$refs.wineDisplay.scrollTo(0, 0);
      }
    },
    productsBack() {
      let hash = localStorage.vina_hash;
      if (hash != "undefined") this.$router.push("/" + hash + "");
    },
    filterProducts() {
      let filtered = this.allProducts;
      // get all products that have this.foodCategory[this.$refs.carousel4[0].currentIndex].id] category
      if (this.foodCategory.length > 0) {
        filtered = filtered.filter((product) => {
          // this currently returns all products that have any of the ids in filter_ids
          return product.filter_ids.some((id) => {
            return (
              this.foodCategory[this.$refs.carousel4[0].currentIndex].id === id
            );
          });
          // I want to return only products that have all the ids in filter_ids
        });
      } else if (this.foodCategory.id) {
        filtered = filtered.filter((product) => {
          return product.filter_ids.some((id) => {
            return this.foodCategory.id === id;
          });
        });
      }

      // remove 24 from this.filters if it exists

      Object.keys(this.filters).forEach((key) => {
        filtered = filtered.filter((product) => {
          // if any id from this.filters[key] exists in product.filter_ids return true
          // return product.filter_ids.some((id) => {
          //   return this.filters[key].includes(id);
          // });
          // if all ids from this.filters[key] exists in product.filter_ids return true
          return this.filters[key].every((id) => {
            return product.filter_ids.includes(id);
          });
        });
      });

      this.products = filtered;

      var searchQuery = this.search;
      window.dispatchEvent(new Event("resize"));
      if (searchQuery === "") {
        this.products = filtered;
      } else {
        this.products = this.products.filter((post) => {
          return post.title.toLowerCase().includes(this.search.toLowerCase());
        });
      }
    },
    async addToFav(obj) {
      let arr = this.$store.getters.favIds;
      let index = arr.indexOf(obj.id);

      if (index > -1) {
        arr.splice(index, 1);
      } else {
        arr.push(obj.id);
      }
      if (this.isFav(obj.id)) {
        await this.$store.dispatch("removeFromFav", { product_id: obj.id });
      } else {
        await this.$store.dispatch("addToFav", { product_id: obj.id });
      }
    },
    isFav(id) {
      if (this.$store.getters.favs.some((item) => item.id === id)) {
        return false;
      }
      return true;
    },
    productsBackToFilters() {
      this.current = true;
      this.menu = false;
      this.search = "";
      this.clearFilters();
    },
    addFiltersFoodCategory(foodCategory, catID, e) {
      // if this.filters[24] key exists
      let oldFilters = this.filters;
      if (this.filters[24]) {
        oldFilters[24] = oldFilters[24].filter(
          (item) => item !== foodCategory[this.oldCarouselIndex].id
        );
      }
      // remove foodCategory[oldCarouselIndex].id from oldFilters
      this.filters = {};
      this.newFilters = {};
      this.toggleFilter(
        foodCategory[this.$refs.carousel4[0].currentIndex].id,
        catID,
        e
      );

      this.foodCategory = foodCategory;
      this.oldCarouselIndex = this.$refs.carousel4[0].currentIndex;
      // append oldFilters to this.filters
      if (oldFilters[24]) {
        this.filters[24] = oldFilters[24];
        oldFilters[24].forEach((item) => {
          this.newFilters[item] = true;
        });
      }

      this.current = false;
      this.filterProducts(true);
    },
    addFiltersFoodCategoryList(foodCategory, catID, e) {
      // if this.filters[24] key exists
      let oldFilters = this.filters;
      if (this.filters[24]) {
        oldFilters[24] = oldFilters[24].filter(
          (item) => item !== foodCategory.id
        );
      }
      this.filters = {};
      this.newFilters = {};
      this.toggleFilter(foodCategory.id, catID, e);

      this.foodCategory = foodCategory;
      // append oldFilters to this.filters
      if (oldFilters[24]) {
        this.filters[24] = oldFilters[24];
        oldFilters[24].forEach((item) => {
          this.newFilters[item] = true;
        });
      }

      this.current = false;
      this.filterProducts();
      var myDiv = document.getElementById("containerDiv");
      myDiv.scrollTop = 0;
    },
    addFilters(filterID, catID, e) {
      this.clearFilters();
      this.toggleFilter(filterID, catID, e);
      this.current = false;
      this.filterProducts();
    },
    clearFilters() {
      this.filters = {};
      this.newFilters = {};

      this.filterProducts();
    },
    getIngredients(items) {
      return items.filter((item) => {
        return item.category.id === 9;
      });
    },
    goTo(value) {
      this.$router.push(value);
      this.menu1 = false;
      this.productsBackToFilters();
      this.productTypeId = this.$route.params.productType;
      this.$store.dispatch("getProductTypes", { fromServer: false });
      this.$store.dispatch("getClient", { fromServer: false });
      this.$store.dispatch("getFilters", { fromServer: false });
      this.$store.dispatch("getRegions", { fromServer: false });
      this.filterProducts();
      // window.location.reload()
    },

    getAvailableFilters(id) {
      let filters = this.client?.available_filters[id];
      if (filters !== undefined) {
        var sortedFilters = Object.keys(filters).map((key) => {
          return filters[key];
        });
        sortedFilters.sort((a, b) => {
          return a.sequence - b.sequence;
        });
      }
      return sortedFilters;
      // var result = items.filter(item => {
      //   return this.availableFilters.has(item.id)
      // })
      // var sortedArray = null
      // if (this.client.client_filters.length !== 0) {
      //   // var type_of_drinks = this.client.client_filters[34]
      //   var filters = this.client.client_filters[29]
      //   for (let i = 0; i < result.length; i++) {
      //     var el = result[i]
      //     if (filters) {
      //       for (let j = 0; j < filters.length; j++) {
      //         var el2 = filters[j]
      //         if (el.id === el2.id) {
      //           result[i]['sequence'] = el2.sequence
      //           break
      //         } else
      //           el['sequence'] = filters.length + 1
      //       }
      //     }
      //     // promjena slike pica ako postoji na klijentu
      //     // if (type_of_drinks) {
      //     //   for (let j = 0; j < type_of_drinks.length; j++) {
      //     //     var drink = type_of_drinks[j]
      //     //     if (el.id === drink.id) {
      //     //       if (drink.pivot_image_url)
      //     //         result[i].image_url = drink.pivot_image_url
      //     //     }
      //     //   }
      //     // }
      //
      //   }
      //
      //   sortedArray = result.sort((a, b) => (a.sequence > b.sequence) ? 1 : -1)
      // } else {
      //   sortedArray = result
      // }
      //
      //
      // return sortedArray
    },

    getImageUrl(filter) {
      var result = this.client.client_filters[filter.filter_category_id].filter(
        (x) => x.id == filter.id
      );
      return result[0].image_url;
    },

    openOrderDialog() {
      this.orderDialog = true;
    },
    submitOrder() {
      this.orderDialog = false;
    },
    setCurrent() {
      let time = 4000;
      this.length_ads = this.ads?.length;
      if (this.ads?.length > 0) {
        let ad = this.ads[this.counter];
        this.current_ads = ad;
        this.incrementCounter();
        if (!this.current_ads.video_url == false) {
          return;
        } else {
          time = 4000;
        }
      }
      this.setAdTimeout = setTimeout(() => {
        this.setCurrent();
      }, time);
    },
    endVideo() {
      this.setCurrent();
    },
    incrementCounter() {
      if (this.counter === this.ads.length - 1) {
        this.counter = 0;
      } else {
        this.counter++;
      }
    },
    closeAd() {
      this.$store.dispatch("showAds", false);
      this.startTimeout();
    },
    startTimeout() {
      clearTimeout(this.menuTimeout);
      this.menuTimeout = setTimeout(() => {
        clearTimeout(this.setAdTimeout);
        this.setCurrent();
        // this.$store.commit("clearFavs");
        this.$store.dispatch("showAds", true);
      }, 3 * 60 * 1000);
    },
  },
  watch: {
    search: function () {
      this.filterProducts();
    },
    ads: function (newVal, oldVal) {
      if (oldVal?.length === 0 && newVal?.length > 0) {
        this.startTimeout();
      }
    },
  },
  created() {
    this.productTypeId = this.$route.params.productType;
    if (
      localStorage.getItem("cocktail_grid") != null &&
      this.productTypeId === "2"
    ) {
      if (localStorage.getItem("cocktail_grid") == 0) {
        this.grid = false;
        this.list = false;
        this.cover = true;
        this.saved = false;
      } else if (localStorage.getItem("cocktail_grid") == 1) {
        this.grid = false;
        this.cover = false;
        this.list = true;
        this.saved = false;
        if (this.$refs.wineDisplay) {
          this.$refs.wineDisplay.scrollTo(0, 0);
        }
      }
    }

    if (
      localStorage.getItem("food_grid") != null &&
      this.productTypeId === "3"
    ) {
      if (localStorage.getItem("food_grid") == 0) {
        this.grid = false;
        this.list = false;
        this.cover = true;
        this.saved = false;
      } else if (localStorage.getItem("food_grid") == 1) {
        this.grid = false;
        this.cover = false;
        this.list = true;
        this.saved = false;
        if (this.$refs.wineDisplay) {
          this.$refs.wineDisplay.scrollTo(0, 0);
        }
      }
    }

    if (
      localStorage.getItem("drink_grid") != null &&
      this.productTypeId === "4"
    ) {
      if (localStorage.getItem("drink_grid") == 0) {
        this.grid = false;
        this.list = false;
        this.cover = true;
        this.saved = false;
      } else if (localStorage.getItem("drink_grid") == 1) {
        this.grid = false;
        this.cover = false;
        this.list = true;
        this.saved = false;
        if (this.$refs.wineDisplay) {
          this.$refs.wineDisplay.scrollTo(0, 0);
        }
      }
    }

    if (this.productTypeId === "3") {
      this.clearFilters();
      this.filterCategories.forEach((el) => {
        if (el.id === 29 && el.product_type_id === 3) {
          let foodCategory = this.getAvailableFilters(el.id);
          this.toggleFilter(foodCategory[0].id, el.id, 1);
          this.current = false;
          this.filterProducts();
        }
      });
    }
    this.$store.dispatch("getProductTypes", { fromServer: false });
    this.$store.dispatch("getClient", { fromServer: false });
    this.$store.dispatch("getFilters", { fromServer: false });
    this.$store.dispatch("getRegions", { fromServer: false });
    this.filterProducts();
    if (this.ads?.length > 0) {
      this.startTimeout();
    }
    document.addEventListener("click", this.startTimeout);
  },
};
</script>
<style lang="scss" scoped>
.products {
  min-height: 100vh;
}

.fancy-cover {
  height: 60vh;
  position: fixed;
  top: 0;
  background: linear-gradient(to top, rgba(0, 0, 0, 1) 50%, rgba(0, 0, 0, 0.3)),
    url("../assets/img/cocktails_bg.png");
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;
}

.ingredient + .ingredient::before {
  content: ", ";
  display: inline;
}

.products-2 {
  padding-top: 6%;
}

.products-col {
  margin-bottom: 150px;
}

.cell-bottle {
  width: auto !important;
  max-height: 48vh;
  z-index: 4;
  margin-top: 0;
  transition: 0.5s ease;
}

.menu-item1 {
  width: 100%;
  height: 12vh;
  background-color: #191c21;
  border: 1px solid #272c33;
  cursor: pointer;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;

  * {
    transition: 0.3s ease;
  }

  p {
    color: rgba(255, 255, 255, 0.4);
    font-size: 20px;
  }

  &:hover {
    * {
      color: #fff !important;
      transition: 0.3s ease;
    }
  }
}

.menu-item2 {
  width: 100%;
  height: 16vh;
  background-color: #191c21;
  border: 1px solid #d7c194;
  cursor: pointer;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;

  * {
    transition: 0.3s ease;
  }

  p {
    color: rgba(255, 255, 255, 0.4);
    font-size: 25px;
  }

  &:hover {
    * {
      color: #fff !important;
      transition: 0.3s ease;
    }
  }
}

.client {
  min-height: 100vh;
  position: relative;
}

.home-2 {
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
}

.text-carousel {
  color: rgba(255, 255, 255, 0.4);
  font-size: 35px;
  text-shadow: -1px 0 #ba8c2f, 0 1px #ba8c2f, 1px 0 #ba8c2f, 0 -1px #ba8c2f;
  color: #d7c194;
}

.carousel-wrap2 {
  height: 90%;
  transition: 0.6s ease;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.grid-col {
  margin-bottom: 200px;
}

.products-4 {
  min-height: 50vh;
  overflow-y: auto;
  padding-top: 15vh;

  h2 {
    z-index: 3;
  }
}

.carousel-text1 {
  z-index: 2;

  align-items: center;
  display: flex;
  flex-direction: column;
  transition: 0.6s ease;
}

.current {
  .carousel-div,
  .carousel-text1 {
    opacity: 1 !important;
    display: flex !important;
  }

  .image-wrap1 {
    .cell-bottle {
      transition: 0.5s ease;
      margin-bottom: -30px;
    }
  }
}

.image-wrap1 {
  width: 100%;
  //height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.carousel1 {
  min-height: 70vh !important;
}

.custom-field {
  width: 170px;
}

.list-button-2 {
  position: absolute;
  z-index: 2;
  left: 5px;
  bottom: 5px;
}

.slide-cell-3 {
  min-height: 70vh;
  background: transparent !important;
}

.slide-cell-2 {
  min-height: 35vh;
  background: transparent !important;
}

.text-carousel-2 {
  color: rgba(255, 255, 255, 0.4);
  font-size: 25px;
  //text-shadow: -1px 0 #ba8c2f, 0 1px #ba8c2f, 1px 0 #ba8c2f, 0 -1px #ba8c2f;
  color: white;
}

.text-carousel-3 {
  color: rgba(255, 255, 255, 0.4);
  font-size: 18px;
  //text-shadow: -1px 0 #ba8c2f, 0 1px #ba8c2f, 1px 0 #ba8c2f, 0 -1px #ba8c2f;
  color: white;
}

.productsVideo {
  max-height: 40vh;
}

.productsImage {
  max-height: 45vh;
}

.product-details {
  min-height: 60vh;
}

.videoShadow {
  box-shadow: 5px 10px 10px 0 rgba(0, 0, 0, 0.4),
    5px 10px 20px 0 rgba(0, 0, 0, 0.2);
}

.backGradient {
  background-image: linear-gradient(to right, #fefefe, #e1ddd2);
}

.font-desc {
  font-size: 1.05em;
}

.videoteka {
  max-height: 65vh;
  overflow-y: scroll;
  width: 100% !important;
  overflow-x: hidden;
  height: 65vh;
}

.videoteka::-webkit-scrollbar {
  display: none;
}

.active-text {
  color: rgba(255, 255, 255, 0.4);
  font-size: 25px;
  text-shadow: -1px 0 #ba8c2f, 0 1px #ba8c2f, 1px 0 #ba8c2f, 0 -1px #ba8c2f;

  color: #d7c194;
}
</style>
